var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", { staticClass: "shared-link-access-denied-page" }, [
    _c(
      "div",
      { staticClass: "box" },
      [
        _vm.reasonQuery === "notlogin"
          ? _c("div", { staticClass: "image" }, [
              _c("img", {
                attrs: {
                  src: require("@/assets/img/static/sorry.png"),
                  alt: "sorry",
                },
              }),
            ])
          : _vm._e(),
        _c("div", { staticClass: "text" }, [
          _c("div", { staticClass: "title" }, [
            _c("h2", [_vm._v(_vm._s(_vm.title))]),
          ]),
          _c("div", { staticClass: "description" }, [
            _c("span", [_vm._v(_vm._s(_vm.description))]),
          ]),
        ]),
        _vm.reasonQuery === "notlogin"
          ? _c(
              "div",
              { staticClass: "container" },
              [
                _c("router-link", { attrs: { to: { name: "signUp" } } }, [
                  _c("button", { staticClass: "primary" }, [
                    _vm._v("新規登録する（無料）"),
                  ]),
                ]),
                _c(
                  "router-link",
                  {
                    staticClass: "anchor-link",
                    attrs: { to: { name: "signIn" } },
                  },
                  [_vm._v("すでに持っているアカウントでログインする")]
                ),
              ],
              1
            )
          : _vm._e(),
        _vm.reasonQuery === "disabled"
          ? _c("router-link", { attrs: { to: { name: "home" } } }, [
              _c("button", { staticClass: "secondary" }, [
                _vm._v("サイトTOPに戻る"),
              ]),
            ])
          : _vm._e(),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }